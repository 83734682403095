/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Typography, Grid, Link, IconButton } from '@mui/material';
import { css } from '@emotion/react';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const footerStyles = css`
  .footer-container {
    background-color: #004d40;
    color: #ffffff;
    padding: 40px 20px;
    text-align: center;
  }

  .footer-logo img {
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
  }

  .footer-info {
    text-align: left;
  }

  .footer-link {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }

  .social-icons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .social-icon {
    color: #ffffff;
    transition: color 0.3s ease;
    &:hover {
      color: #ff5722;
    }
  }

  .footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 20px;
    padding-top: 20px;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Footer = () => {
  return (
    <div css={footerStyles}>
        <Box className="footer-container">
        <Grid container spacing={4} alignItems="center">
            {/* Logo Section */}
            <Grid item xs={12} md={4} className="footer-logo">
            <img src="/assets/logo/android-chrome-512x512.png" alt="Fatema's Learning Frontier Logo" />
            </Grid>

            {/* Contact Information */}
            <Grid item xs={12} md={8} className="footer-info">
                <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 700 }}>
                    Contact Us
                </Typography>
                <Typography sx={{ marginBottom: '10px', color:'#ffffff' }}>
                    <strong>Address:</strong> MR4W+76 Sai Paradais, 571, Mufaddal Villa, Pipliya Pala, Nihalpur Mundi, Indore, Madhya Pradesh 452012
                </Typography>
                <Typography sx={{ marginBottom: '10px', color:'#ffffff' }}>
                    <strong>Phone:</strong>{' '}
                    <Link href="tel:+919303889588" className="footer-link">
                    +91 930 388 9588
                    </Link>
                </Typography>
                <Typography sx={{ marginBottom: '10px', color:'#ffffff' }}>
                    <strong>Email:</strong>{' '}
                    <Link href="mailto:fatemaslearningfrontier@gmail.com" className="footer-link">
                    fatemaslearningfrontier@gmail.com
                    </Link>
                </Typography>
                <Typography sx={{ marginBottom: '10px', color:'#ffffff' }}>
                    <strong>Class Timings:</strong> Evening Batch 4 PM - 8 PM
                </Typography>
            </Grid>
        </Grid>

        {/* Social Media Icons */}
        <Box className="social-icons">
            <IconButton
                href="https://www.instagram.com/fatemaslearningfrontier"
                target="_blank"
                className="social-icon"
            >
            <InstagramIcon fontSize="large" />
            </IconButton>
            <IconButton
                href="https://www.facebook.com/people/fatemaslearningfrontier/61552793206356"
                target="_blank"
                className="social-icon"
            >
            <FacebookIcon fontSize="large" />
            </IconButton>
            <IconButton
                href="https://wa.me/+919303889588?text=Hello!%20I%27m%20interested%20in%20learning%20more%20about%20your%20courses."
                target="_blank"
                className="social-icon"
            >
            <WhatsAppIcon fontSize="large" />
            </IconButton>
        </Box>

        {/* Footer Bottom */}
        <Box className="footer-bottom">
            <Typography sx={{color:'#ffffff'}}>© 2024 Fatema's Learning Frontier. All Rights Reserved.</Typography>
            <Typography sx={{color:'#ffffff'}}>
            Designed with ❤️ by{' '}
            <Link href="https://technoformatics.com" target="_blank" className="footer-link">
                TechnoFormatics
            </Link>
            </Typography>
        </Box>
        </Box>
    </div>
  );
};

export default Footer;
