import React from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import CourseCard from './CourseCard';
import LanguageIcon from '@mui/icons-material/Language';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonIcon from '@mui/icons-material/Person';
import useWhatsApp from '../Hooks/useWhatsApp';

const features = [
    {
      title: 'Speak English Fluently with Confidence',
      description:
        'Transform your ability to speak English fluently and confidently in everyday and professional situations. Our expert-led sessions focus on pronunciation, vocabulary building, and overcoming fear of speaking—perfectly tailored for women aiming to excel.',
      icon: <LanguageIcon style={{ fontSize: '3rem', color: '#004d40' }} />,
    },
    {
      title: 'Master Grammar with Ease',
      description:
        'Say goodbye to embarrassing grammar mistakes. Learn the foundations of English grammar with clear, practical rules and tips to make your communication polished, error-free, and impactful—whether in conversations, emails, or presentations.',
      icon: <MenuBookIcon style={{ fontSize: '3rem', color: '#004d40' }} />,
    },
    {
      title: 'Build Confidence and Transform Your Personality',
      description:
        'Our course is more than just English—it’s about empowerment. Gain the confidence to speak in public, ace interviews, and build a magnetic personality. This transformative journey is designed exclusively for women who want to achieve more in life.',
      icon: <PersonIcon style={{ fontSize: '3rem', color: '#004d40' }} />,
    },
  ];
  
const CourseSection = () => {
  const handleWhatsAppClick = useWhatsApp(); 
  return (
    <Box
      id="course"
      sx={{
        backgroundColor: '#f9f9f9',
        padding: { xs: '40px 10px', sm: '60px 20px'}, // Adjust padding for small devices
        width: '100% !important',
      }}
    >
      {/* Section Title */}
      <Typography
        variant="h4"
        sx={{
          textAlign: 'center',
          fontWeight: 700,
          marginBottom: { xs: '20px', sm: '30px' }, // Adjust margin for small devices
          color: '#004d40',
          fontSize: { xs: '1.8rem', sm: '2.4rem' }, // Adjust font size for small devices
        }}
      >
        What You'll Learn
      </Typography>

      {/* Responsive Grid */}
      <Grid
        container
        spacing={{ xs: 2, sm: 4 }} // Adjust spacing between cards for smaller devices
        sx={{
          maxWidth: '1200px',
          margin: '0 auto !important',
          width: '100% !important'
        }}
      >
        {features.map((feature, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'center', 
              padding: '16px 16px !important'
            }}
          >
            <CourseCard
              title={feature.title}
              description={feature.description}
              icon={feature.icon}
            />
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
            textAlign: 'center',
            marginTop: { xs: '20px', sm: '30px' },
            padding: { xs: '20px', sm: '30px' },
            backgroundColor: '#f8f8f8',
            borderRadius: '12px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            width: '100% !important',
        }}
        >
        {/* Emotional and FOMO-Oriented Line */}
        <Typography
            sx={{
            fontSize: { xs: '1rem', sm: '1.2rem' },
            fontWeight: 600,
            color: '#d32f2f', // Red color for urgency
            marginBottom: '15px',
            }}
        >
            Don’t let this opportunity slip away! Your future self will thank you for starting today.
        </Typography>

        {/* Culturally Motivated Line */}
        <Typography
            sx={{
            fontSize: { xs: '0.9rem', sm: '1rem' },
            fontWeight: 400,
            color: '#5f6368',
            marginBottom: '20px',
            }}
        >
            Imagine confidently speaking English at family gatherings, schools, or workplaces. Empower yourself to lead and inspire others!
        </Typography>

        {/* Action Button */}
        <Button
            onClick={handleWhatsAppClick}
            sx={{
            backgroundColor: '#004d40',
            color: '#ffffff',
            padding: { xs: '10px 15px', sm: '10px 20px' },
            fontSize: { xs: '0.9rem', sm: '1rem' },
            fontWeight: 'bold',
            textTransform: 'none',
            borderRadius: '8px',
            '&:hover': {
                backgroundColor: '#003731',
            },
            }}
        >
            Enroll Now
        </Button>

        {/* Supporting Scarcity Line */}
        <Typography
            sx={{
            fontSize: '0.9rem',
            color: '#757575',
            marginTop: '15px',
            fontStyle: 'italic',
            }}
        >
            Limited seats available. Next batch starts soon—act now!
        </Typography>
        </Box>

    </Box>
  );
};

export default CourseSection;
