/**
 * This line is a special comment that instructs the compiler to use the Emotion library's
 * `@emotion/react` package for processing JSX. Emotion is a library designed for writing
 * CSS styles with JavaScript, allowing for dynamic styling in React components.
 * 
 * By including this directive, the file is set up to leverage Emotion's powerful styling
 * capabilities, such as CSS-in-JS, which enables styles to be scoped to components and
 * allows for conditional and dynamic styling based on component state or props.
 */
 /** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Typography, Grid, Divider, Button } from '@mui/material';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import useWhatsApp from '../Hooks/useWhatsApp';


const MotionDiv = motion.div;

const aboutStyles = css`
  .about-container {
    padding: 80px 40px;
    background-color: #f3f4f6; /* Clean light background */
    color: #1b1b1b; /* Dark primary text color */
    position: relative;
    overflow: hidden;
  }

  .about-heading {
    font-size: 2.8rem;
    font-weight: 700;
    color: #004d40; /* Primary theme color */
    text-align: center;
    margin-bottom: 20px;
    animation-duration: 1s;
    animation-delay: 0.1s;
  }

  .about-divider {
    background-color: #ffab91; /* Accent color */
    height: 4px;
    width: 100px;
    margin: 10px auto;
    animation-duration: 1s;
    animation-delay: 0.3s;
  }

  .about-content {
    font-size: 1.2rem;
    line-height: 1.8;
    margin: 20px 0;
    color: #5f6368; /* Subtle secondary text */
    animation-duration: 1.5s;
    animation-delay: 0.5s;
    text-align: justify;
  }

  .about-highlight {
    font-weight: 700;
    color: #004d40; /* Emphasized with primary theme color */
  }

  .about-image {
    border-radius: 12px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    animation-duration: 1.5s;
    animation-delay: 0.7s;
  }

  .about-button {
    background-color: #004d40;
    color: white;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    text-transform: none;
    border-radius: 8px;
    font-weight: bold;
    animation-duration: 1s;
    animation-delay: 1s;
    &:hover {
      background-color: #003731;
    }
  }

  @media (max-width: 768px) {
    .about-heading {
      font-size: 2rem;
    }

    .about-content {
      font-size: 1rem;
    }
  }
`;

const AboutUsSection = () => {
    const handleWhatsAppClick = useWhatsApp();
  return (
    <div css={aboutStyles} id="about">
        <Box className="about-container">
        {/* Section Heading */}
        <Typography variant="h2" className="about-heading">
            About Us
        </Typography>
        <Divider className="about-divider" />

        <Grid container spacing={4} alignItems="center" justifyContent="center" sx={{ mt: 5 }}>
            {/* Image Section */}
            <Grid item xs={12} md={5}>
                <MotionDiv
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: .8 }}
                >
                    <Box
                        component="img"
                        src="/assets/images/fatema-lightwala.jpg" // Replace with actual image
                        alt="Fatema Teaching"
                        className="about-image"
                        sx={{ width: '100%' }}
                    />
                </MotionDiv>
            </Grid>

            {/* Text Content Section */}
            <Grid item xs={12} md={7}>
                <MotionDiv
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: .8 }}
                >
                    <Typography variant="body1" className="about-content">
                        <span className="about-highlight">Empowering lives since 1990</span>, Fatema has dedicated over three decades to transforming individuals into confident English speakers. 
                        While her journey began with helping students from all walks of life, her passion has now evolved to focus on empowering women—housewives, working professionals, and aspiring achievers—who dream of making a mark in their personal and professional lives.
                    </Typography>
                </MotionDiv>
                <MotionDiv
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1.2 }}
                >
                    <Typography variant="body1" className="about-content">
                        At <span className="about-highlight">Fatema's Learning Frontier</span>, she provides women with a platform 
                        to <span className="about-highlight">reclaim their confidence</span> through expert coaching 
                        in <span className="about-highlight">spoken English, grammar, and communication skills</span>. 
                        Fatema understands the unique challenges women face and is committed to helping 
                        them <span className="about-highlight">overcome language barriers</span>, 
                        whether they are returning to the workforce, starting a career, or enhancing their daily communication skills.
                    </Typography>
                </MotionDiv>
                <MotionDiv
                    initial={{ opacity: 0, x: 70 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1.6 }}
                >
                    <Typography variant="body1" className="about-content">
                        With a legacy built on trust and excellence, Fatema has helped thousands of women discover their potential. 
                        Her qualifications, including <span className="about-highlight">B.Ed, D.L.Ed, BA English, and MA English</span>, 
                        combined with her empathetic approach, make her the ideal mentor for women who are ready to take control of their future. 
                        Whether you’re a homemaker aspiring to be independent, a professional aiming to advance, or someone looking to 
                        achieve <span className="about-highlight">personal growth</span>, Fatema’s personalized coaching is designed to empower you to succeed.
                    </Typography>
                </MotionDiv>
                <MotionDiv
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 2 }}
                >
                    <Button onClick={handleWhatsAppClick} className="about-button">Learn More</Button>
                </MotionDiv>
            </Grid>
        </Grid>
        </Box>
    </div>
  );
};

export default AboutUsSection;
