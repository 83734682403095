/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { css } from '@emotion/react';
import useWhatsApp from '../Hooks/useWhatsApp';


const contactStyles = css`
  .contact-container {
    background-color: #ffffff;
    padding: 60px 20px;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    margin: 40px auto;
    max-width: 1200px;
  }

  .contact-info {
    text-align: left;
    padding: 20px;
  }

  .map-container iframe {
    width: 100%;
    height: 300px;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .contact-detail {
    font-size: 1.1rem;
    color: #5f6368;
    margin-bottom: 10px;
  }

  .cta-button {
    background-color: #004d40;
    color: white;
    font-weight: bold;
    text-transform: none;
    padding: 10px 20px;
    border-radius: 8px;
    margin-top: 30px;
    &:hover {
      background-color: #003731;
    }
  }
`;

const ContactUs = () => {
  const handleWhatsAppClick = useWhatsApp(); 
  return (
    <div id="contact" css={contactStyles}>
        <Box className="contact-container">
        <Typography
            variant="h4"
            sx={{
            fontWeight: 700,
            marginBottom: '20px',
            color: '#004d40',
            }}
        >
            Contact Us
        </Typography>

        <Grid container spacing={4}>
            {/* Contact Details */}
            <Grid item xs={12} md={6}>
            <Box className="contact-info">
                <Typography className="contact-detail">
                <strong>Address:</strong> MR4W+76 Sai Paradais, 571, Mufaddal Villa, Pipliya Pala, Nihalpur Mundi, Indore, Madhya Pradesh 452012
                </Typography>
                <Typography className="contact-detail">
                <strong>Phone:</strong> <a href="tel:+919303889588" style={{ textDecoration: 'none', color: '#004d40' }}>+91 930 388 9588</a>
                </Typography>
                <Typography className="contact-detail">
                <strong>Email:</strong> <a href="mailto:fatemaslearningfrontier@gmail.com" style={{ textDecoration: 'none', color: '#004d40' }}>fatemaslearningfrontier@gmail.com</a>
                </Typography>
                <Typography className="contact-detail">
                <strong>Class Timings:</strong> Evening Batch 4 PM - 8 PM
                </Typography>
                <Button onClick={handleWhatsAppClick} className="cta-button">
                Get in Touch
                </Button>
            </Box>
            </Grid>

            {/* Map */}
            <Grid item xs={12} md={6}>
            <Box className="map-container">
                <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14727.722701478267!2d75.8452344!3d22.6563732!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd9b9d970745%3A0x19c7f213e616bfa3!2sFatema%E2%80%99s%20Learning%20Frontier%20%7C%20English%20Coaching%20Classes%20%7C%20Coaching%20Classes!5e0!3m2!1sen!2sin!4v1732046644662!5m2!1sen!2sin"
                allowFullScreen=""
                loading="lazy"
                title="Fatema’s Learning Frontier Best English Coaching Classes in Indore"
                ></iframe>
            </Box>
            </Grid>
        </Grid>
        </Box>
    </div>
  );
};

export default ContactUs;