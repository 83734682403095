import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#004d40', // Deep Teal (professional, calm, and elegant)
    },
    secondary: {
      main: '#ffab91', // Soft Coral (friendly and inviting)
    },
    background: {
      default: '#f3f4f6', // Very Light Gray (clean and neutral background)
      paper: '#ffffff', // Pure White for paper-like contrast
    },
    text: {
      primary: '#1b1b1b', // Almost Black (elegant and readable)
      secondary: '#5f6368', // Muted Gray (soft and less dominant)
    },
  },
  typography: {
    fontFamily: `'Nunito', 'Roboto', 'Arial', sans-serif`, // Elegant, friendly, and professional fonts
    h1: {
      fontSize: '2.8rem',
      fontWeight: 700,
      color: '#1b1b1b', // Matches primary text color
    },
    h2: {
      fontSize: '2.2rem',
      fontWeight: 600,
      color: '#1b1b1b',
    },
    h3: {
      fontSize: '1.8rem',
      fontWeight: 600,
      color: '#1b1b1b',
    },
    body1: {
      fontSize: '1rem',
      color: '#1b1b1b',
    },
    body2: {
      fontSize: '0.9rem',
      color: '#5f6368', // Secondary text color for less emphasis
    },
    button: {
      textTransform: 'capitalize', // More conversational, less formal than uppercase
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // Subtle rounded corners for buttons
          padding: '8px 16px',
          fontWeight: 'bold',
        },
        containedPrimary: {
          backgroundColor: '#004d40', // Deep Teal for primary buttons
          color: '#ffffff', // Text in white for contrast
          '&:hover': {
            backgroundColor: '#003731', // Slightly darker teal on hover
          },
        },
        containedSecondary: {
          backgroundColor: '#ffab91', // Soft Coral for secondary actions
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#ff8a65', // Brighter coral on hover
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 1.6, // Improves readability
        },
      },
    },
  },
});

export default theme;
