import React from 'react';
import { Box } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const FloatingWhatsApp = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 1000,
        backgroundColor: '#25d366', // WhatsApp green
        color: '#fff',
        borderRadius: '50%',
        width: 60,
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        cursor: 'pointer',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'scale(1.1)',
          boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      <WhatsAppIcon fontSize="large" />
    </Box>
  );
};

export default FloatingWhatsApp;
