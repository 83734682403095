/**
 * Hook to add WhatsApp functionality to any button.
 * @param {string} phoneNumber - WhatsApp number with country code (e.g., +919039018406).
 * @param {string} message - Predefined message for WhatsApp chat.
 * @returns {Function} - onClick handler to attach to your button.
 */
const useWhatsApp = (
    phoneNumber = '+919303889588', 
    message = "Hello! I'm interested in learning more about your English coaching services!") => {
  return () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
  };
};

export default useWhatsApp;
