/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import useWhatsApp from '../Hooks/useWhatsApp';


const whyChooseStyles = css`
  .choose-container {
    background-color: #ffffff;
    padding: 60px 20px;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
  }

  .highlight {
    color: #ff5722; /* Accent color for scarcity lines */
    font-weight: bold;
  }

  .content-grid {
    max-width: 1200px;
    margin: 0 auto;
    width: 100% !important;
  }

  .cta-button {
    background-color: #004d40;
    color: white;
    font-weight: bold;
    text-transform: none;
    padding: 10px 20px;
    border-radius: 8px;
    margin-top: 30px;
    &:hover {
      background-color: #003731;
    }
  }
  .content-grid .item-grid {
    padding: 16px !important;
  }
`;

const WhyChooseUs = () => {
    const handleWhatsAppClick = useWhatsApp();

  return (
    <div css={whyChooseStyles} id="why">        
        <Box className="choose-container">
            <Typography
            variant="h4"
            sx={{
                fontWeight: 700,
                marginBottom: '20px',
                color: '#004d40',
            }}
            >
            Why Choose <span className="highlight">Fatema Lightwala</span>?
            </Typography>

            <Typography
            variant="h6"
            sx={{
                marginBottom: '40px',
                color: '#5f6368',
            }}
            >
            You don’t just need a teacher—you need a mentor with unmatched experience who can transform your life. Here's why Fatema is your only choice:
            </Typography>

            <Grid container spacing={4} className="content-grid">
            {/* Unique Point 1 */}
            <Grid className='item-grid' item xs={12} sm={6} md={4}>
                <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                style={{
                    padding: '20px',
                    backgroundColor: '#f8f8f8',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                }}
                >
                <Typography
                    sx={{
                    fontSize: '1.5rem',
                    fontWeight: '600',
                    color: '#004d40',
                    marginBottom: '10px',
                    }}
                >
                    30+ Years of Experience
                </Typography>
                <Typography
                    sx={{
                    color: '#5f6368',
                    fontSize: '1rem',
                    lineHeight: 1.6,
                    }}
                >
                    Since 1990, Fatema has helped thousands of women achieve fluency and confidence. Her decades of expertise are your guarantee of success.
                </Typography>
                </motion.div>
            </Grid>

            {/* Unique Point 2 */}
            <Grid className='item-grid' item xs={12} sm={6} md={4}>
                <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                style={{
                    padding: '20px',
                    backgroundColor: '#f8f8f8',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                }}
                >
                <Typography
                    sx={{
                    fontSize: '1.5rem',
                    fontWeight: '600',
                    color: '#004d40',
                    marginBottom: '10px',
                    }}
                >
                    Tailored for Indian Women
                </Typography>
                <Typography
                    sx={{
                    color: '#5f6368',
                    fontSize: '1rem',
                    lineHeight: 1.6,
                    }}
                >
                    Fatema understands the unique challenges faced by Indian women and designs her coaching to empower you in every aspect of life.
                </Typography>
                </motion.div>
            </Grid>

            {/* Unique Point 3 */}
            <Grid className='item-grid' item xs={12} sm={6} md={4}>
                <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                style={{
                    padding: '20px',
                    backgroundColor: '#f8f8f8',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                }}
                >
                <Typography
                    sx={{
                    fontSize: '1.5rem',
                    fontWeight: '600',
                    color: '#004d40',
                    marginBottom: '10px',
                    }}
                >
                    Proven Transformations
                </Typography>
                <Typography
                    sx={{
                    color: '#5f6368',
                    fontSize: '1rem',
                    lineHeight: 1.6,
                    }}
                >
                    Women who’ve worked with Fatema have gone on to excel in interviews, careers, and personal growth. Be the next success story.
                </Typography>
                </motion.div>
            </Grid>
            </Grid>

            {/* Scarcity Line */}
            <Typography
            sx={{
                fontSize: '1.2rem',
                color: '#ff5722',
                marginTop: '40px',
                fontWeight: 'bold',
            }}
            >
            Opportunities like this don’t wait—limited seats available!
            </Typography>

            {/* Call-to-Action Button */}
            <Button 
                onClick={handleWhatsAppClick}
                className="cta-button">
            Join Now and Transform Your Future
            </Button>
        </Box>
    </div>

  );
};

export default WhyChooseUs;
