/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { css } from '@emotion/react';
import useWhatsApp from './Hooks/useWhatsApp';


const headerStyles = css`
  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 91px;
  }

  .logo {
    font-weight: bold;
    font-size: 1.5rem;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

  }

  .nav-links {
    display: flex;
    gap: 20px;
  }

  .nav-link {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    cursor:pointer;
    &:hover {
      color: #ff5722;
    }
  }

  .cta-button {
    font-weight: bold;
  }

  @media (max-width: 786px) {
    .nav-links, .toolbar .cta-button {
      display: none;
    }
  }
`;

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleWhatsAppClick = useWhatsApp();
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  const scrollWithOffset = (el) => {
    const yOffset = -91; // Adjust for any fixed header or offset
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({
      top: yCoordinate,
      behavior: 'smooth', // Enables smooth scrolling
    });
  };


  return (
    <>
      <AppBar position="sticky" color="default" css={headerStyles}>
        <Toolbar className="toolbar">
          {/* Logo */}
          <Typography variant="h6" component="div" sx={{ display: 'flex' }}>
            <Link to="/" className="logo">
                <img 
                    src="/assets/logo/android-chrome-192x192.png" 
                    alt="FLF English Logo" 
                    style={{ height: '80px', objectFit: 'contain' }}
                />
            </Link>
          </Typography>

          {/* Navigation Links (Visible on desktop) */}
          <Box component="nav" className="nav-links">
            <Link 
                smooth={scrollWithOffset}
                to="#hero" 
                className="nav-link"
            >Home</Link>
            <Link 
                smooth={scrollWithOffset} 
                to="#about" 
                className="nav-link"
            >About Us</Link>
            <Link 
                smooth={scrollWithOffset} 
                to="#course" 
                className="nav-link"
            >Our Offering</Link>
            <Link 
                smooth={scrollWithOffset} 
                to="#faq" 
                className="nav-link"
            >FAQ</Link>
            <Link 
                smooth={scrollWithOffset} 
                to="#why" 
                className="nav-link"
            >Why Fatema?</Link>
            <Link 
                smooth={scrollWithOffset} 
                to="#contact" 
                className="nav-link"
            >Contact Us</Link>
          </Box>

          {/* Enroll Now CTA Button */}
          <Button onClick={handleWhatsAppClick} className="cta-button" variant="contained" size="medium">
            Enroll Now
          </Button>

          {/* Mobile Menu Icon */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Navigation */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <List>
            <ListItemButton>
              <ListItemText>
                <Link 
                    smooth={scrollWithOffset}
                    to="#hero" 
                    className="nav-link"
                >Home</Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>
                <Link 
                    smooth={scrollWithOffset}
                    to="#about" 
                    className="nav-link"
                >About Us</Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>
                <Link 
                    smooth={scrollWithOffset}
                    to="#course" 
                    className="nav-link"
                >Our Offering</Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>
                <Link 
                    smooth={scrollWithOffset}
                    to="#faq" 
                    className="nav-link"
                >FAQ</Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>
                <Link 
                    smooth={scrollWithOffset}
                    to="#why" 
                    className="nav-link"
                >Why Fatema?</Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>
                <Link 
                    smooth={scrollWithOffset}
                    to="#contact" 
                    className="nav-link"
                >Contact Us</Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton>
              <Button onClick={handleWhatsAppClick} className="cta-button" variant="contained" size="small">
                Enroll Now
              </Button>
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
