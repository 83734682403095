/**
 * This line is a special comment that instructs the compiler to use the Emotion library's
 * `@emotion/react` package for processing JSX. Emotion is a library designed for writing
 * CSS styles with JavaScript, allowing for dynamic styling in React components.
 * 
 * By including this directive, the file is set up to leverage Emotion's powerful styling
 * capabilities, such as CSS-in-JS, which enables styles to be scoped to components and
 * allows for conditional and dynamic styling based on component state or props.
 */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { Parallax } from 'react-parallax';
import { Box, Typography, Button } from '@mui/material';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import useWhatsApp from '../Hooks/useWhatsApp';

const MotionDiv = motion.div;
const heroStyles = css`
  .hero-container {
    height: calc(100vh - 91px); /* Adjust based on your header's height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: 0 20px;
  }

  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .6); /* Overlay for better text contrast */
    z-index: 0;
  }

  .hero-content {
    position: relative;
    z-index: 2; /* Ensures text is above overlay */
  }

  .hero-heading {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.2;
    color: #f9f6f1;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
    margin-bottom: 1rem;
    @media (max-width: 786px) {
      font-size: 2.5rem;
    }
  }

  .hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-weight: 400;
    @media (max-width: 786px) {
      font-size: 1rem;
    }
  }

  .hero-button {
    background-color: #56574cfc;
    color: #f8f0e1;
    font-size: 1.2rem;
    padding: 0.8rem 2.5rem;
    border-radius: 8px;
    font-weight: bold;
    text-transform: none;
    transition: transform 0.3s ease, background-color 0.3s ease;
    &:hover {
      background-color: #9a8f7e;
      transform: scale(1.05);
    }
  }
`;

const HeroSection = () => {
  const handleWhatsAppClick = useWhatsApp();
  return (
    <Parallax
      bgImage="https://images.unsplash.com/photo-1590650153855-d9e808231d41?q=80&w=1800&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" // Replace with your image URL
      strength={500}
      css={heroStyles}
    >
      <Box className="hero-container" id="hero"
      >
        {/* Overlay */}
        <Box className="hero-overlay" />

        {/* Content */}
        <Box className="hero-content">
            <MotionDiv
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.9 }}
            >
                <Typography variant="h1" className="hero-heading">
                    Master English with Confidence
                </Typography>
            </MotionDiv>
            <MotionDiv
                initial={{ opacity: 0, y: -30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.3 }}
            >
                <Typography variant="h6" className="hero-subtitle">
                    Expert English coaching for women in Indore. Join us to enhance your spoken English, grammar, and communication skills.
                </Typography>
            </MotionDiv>
            <MotionDiv
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
                <Button onClick={handleWhatsAppClick} className="hero-button" variant="contained">
                    Enroll Now
                </Button>
          </MotionDiv>
        </Box>
      </Box>
    </Parallax>
  );
};

export default HeroSection;
