import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useWhatsApp from '../Hooks/useWhatsApp';


const faqs = [
  {
    question: "What makes Fatema's Learning Frontier the best English coaching for women in Indore?",
    answer: `At Fatema's Learning Frontier, we focus exclusively on empowering women by providing personalized coaching in spoken English, grammar, and confidence-building. 
    With over 30 years of experience, we understand the unique needs of Indian women, offering practical learning methods, flexible timings, and a supportive environment.`,
  },
  {
    question: "Who is this English course designed for?",
    answer: `Our course is designed for women of all ages and backgrounds. Whether you're a homemaker looking to improve communication, a professional aiming to advance your career, or a student preparing for interviews, this course is for you.`,
  },
  {
    question: "How can this course help housewives?",
    answer: `Housewives can benefit greatly by gaining fluency in English to communicate confidently in social settings, help their children with studies, and explore new opportunities like freelancing or online jobs.`,
  },
  {
    question: "How long does it take to become fluent in English?",
    answer: `The time needed to achieve fluency varies depending on your starting level. Most women notice significant improvements within 3 to 6 months by attending regular classes, completing assignments, and participating in real-world speaking activities.`,
  },
  {
    question: "Can I join the course if I am a complete beginner?",
    answer: `Absolutely! Our course is perfect for beginners. You'll start with the basics, such as simple vocabulary and sentence formation, and gradually build your confidence and fluency.`,
  },
  {
    question: "How does learning English boost confidence in women?",
    answer: `English is more than a language—it's a key to self-expression. By mastering English, women can communicate confidently, participate in discussions, and express themselves clearly in professional, academic, and personal settings.`,
  },
  {
    question: "What makes your English coaching different from others?",
    answer: `Our focus is on practical, real-world learning. From group discussions and role-playing to grammar workshops, we ensure that every woman gains hands-on experience. Plus, our courses are tailored exclusively for Indian women, addressing their unique challenges.`,
  },
  {
    question: "Can learning English help me get a better job?",
    answer: `Yes! Proficiency in English is essential for most jobs today. Whether you're applying for a corporate role, teaching position, or freelance work, speaking English fluently gives you a competitive edge in interviews and professional settings.`,
  },
  {
    question: "Are your classes flexible for working women?",
    answer: `Yes, we offer flexible evening and weekend classes designed to fit into the busy schedules of working professionals, housewives, and students.`,
  },
  {
    question: "Why should Indian women learn English?",
    answer: `Learning English allows Indian women to participate in global conversations, access better career opportunities, and support their families more effectively. It's a step toward empowerment and independence.`,
  },
  {
    question: "What teaching methods do you use?",
    answer: `We use interactive teaching methods like role-playing, group discussions, and real-life simulations to help women practice spoken English and build confidence. Our approach is student-centric, ensuring every participant feels supported.`,
  },
  {
    question: "Can I improve my English pronunciation in this course?",
    answer: `Yes, we focus on pronunciation improvement with techniques like phonetics practice, accent training, and listening exercises to help you speak clearly and confidently.`,
  },
  {
    question: "What other skills will I learn besides English fluency?",
    answer: `In addition to fluency, you'll develop skills like effective communication, public speaking, grammar accuracy, and personality development. These skills ensure you're confident in both personal and professional environments.`,
  },
  {
    question: "What if I miss a class?",
    answer: `We provide flexible options to make up for missed classes, including additional sessions or access to recorded materials to help you stay on track.`,
  },
  {
    question: "Do I need to buy any books or materials?",
    answer: `No. All course materials are provided as part of the program, including grammar guides, vocabulary lists, and practice exercises.`,
  },
  {
    question: "How can I practice spoken English outside of class?",
    answer: `We encourage daily practice by engaging in conversations with family and friends, using language apps, and participating in our online discussion forums to improve speaking fluency.`,
  },
];


const FAQSection = () => {
  const handleWhatsAppClick = useWhatsApp();

  return (
    <Box
      id="faq"
      sx={{
        backgroundColor: '#f9f9f9',
        padding: { xs: '40px 20px', sm: '60px 40px' },
        borderRadius: '12px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        margin: '30px auto',
        maxWidth: '800px',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: 'center',
          fontWeight: 700,
          marginBottom: '30px',
          color: '#004d40',
        }}
      >
        Frequently Asked Questions
      </Typography>

      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`faq-${index}-content`}>
            <Typography sx={{ fontWeight: 600 }}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <Box sx={{ textAlign: 'center', marginTop: '30px' }}>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 500,
            marginBottom: '15px',
            color: '#757575',
          }}
        >
          Still have questions? Contact us for more details!
        </Typography>
        <Button
          onClick={handleWhatsAppClick}
          sx={{
            backgroundColor: '#004d40',
            color: '#ffffff',
            padding: '10px 20px',
            fontSize: '1rem',
            fontWeight: 'bold',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#003731',
            },
          }}
        >
          Enroll Now
        </Button>
      </Box>

    </Box>
    
  );
};

export default FAQSection;
