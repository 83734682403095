import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Parallax } from 'react-parallax';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Parallax
      bgImage="https://images.unsplash.com/photo-1578847784457-80f3a212ffa6?q=80&w=1200&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" // Replace with a cute background image URL
      strength={300}
    >
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(5px)',
          padding: '2rem',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: '6rem',
            fontWeight: 700,
            color: '#ff6f61',
          }}
        >
          404
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: '1.5rem',
            margin: '1rem 0',
            color: '#555',
          }}
        >
          Oops! The page you’re looking for doesn’t exist.
        </Typography>
        <Box
          sx={{
            width: '250px',
            height: '250px',
            background: `url('/assets/logo/android-chrome-512x512.png') no-repeat center center / contain`, // Replace with a cute graphic
            margin: '2rem 0',
            animation: 'float 3s ease-in-out infinite',
          }}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            padding: '0.8rem 2rem',
            borderRadius: '30px',
            fontSize: '1.2rem',
            backgroundColor: '#ff6f61',
            '&:hover': {
              backgroundColor: '#e5584f',
            },
          }}
          onClick={() => navigate('/')}
        >
          Take Me Home
        </Button>
      </Box>
    </Parallax>
  );
};

export default NotFoundPage;
