import Header from "./Components/Header";
import HomePage from "./Modules/HomePage";
import Footer from "./Components/Footer";
import FloatingWhatsApp from "./Components/FloatingWhatsApp";
import useWhatsApp from "./Components/Hooks/useWhatsApp";
import { Routes, Route } from 'react-router-dom';
import NotFoundPage from "./Modules/NotFoundPage";



function App() {
  const handleWhatsAppClick = useWhatsApp()
    return (
    <>
      <Header/>
        <Routes>
          {/* Define your existing routes */}
          <Route path="/" element={<HomePage/>} />
          {/* Redirect all other routes to Home */}
          <Route path="*" element={<NotFoundPage/>} />
        </Routes>
      <Footer/>
      <FloatingWhatsApp onClick={handleWhatsAppClick}/>
    </>
  );
}

export default App;