import React from 'react';
import { Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';

const CourseCard = ({ title, description, icon }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
      style={{
        padding: '20px',
        backgroundColor: '#f8f8f8',
        borderRadius: '12px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        position: 'relative',
      }}
    >
      {/* Icon */}
      {icon && (
        <Box
          style={{
            fontSize: '2rem',
            color: '#004d40',
            marginBottom: '10px',
          }}
        >
          {icon}
        </Box>
      )}

      {/* Title */}
      <Typography
        style={{
          fontSize: '1.5rem',
          fontWeight: '600',
          color: '#004d40',
          marginBottom: '10px',
        }}
      >
        {title}
      </Typography>

      {/* Description */}
      <Typography
        style={{
          fontSize: '1rem',
          color: '#5f6368',
          lineHeight: 1.6,
        }}
      >
        {description}
      </Typography>
    </motion.div>
  );
};

export default CourseCard;
