import AboutUsSection from "../Components/Homepage/AboutUsSection";
import HeroSection from "../Components/Homepage/HeroSection";
import CourseSection from "../Components/Homepage/CourseSection";
import { Parallax } from 'react-parallax';
import FAQSection from "../Components/Homepage/FAQSection";
import WhyChooseUs from "../Components/Homepage/WhyChooseUs";
import CallToAction from "../Components/Homepage/CallToAction";
import ContactUs from "../Components/Homepage/ContactUs";

const HomePage = () => {
  return (
    <div>
      {/* Add your component content here */}
        <HeroSection/>
        <AboutUsSection/>
        <CourseSection/>
        <Parallax
            bgImage="https://images.unsplash.com/photo-1500964757637-c85e8a162699?q=80&w=3003&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            strength={500}
        >
            <div style={{ height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1em'}}>
                <h2 style={{ color: 'white', textAlign: 'center', fontSize: '2rem', fontWeight: 'bold' }}>
                Empowering Indian Women: Speak English Fluently, Lead Boldly, Live Confidently.
                </h2>
            </div>
        </Parallax>
        <FAQSection/>
        <WhyChooseUs/>
        <CallToAction/>
        <ContactUs/>
    </div>
  );
};
export default HomePage;
