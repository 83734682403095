/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { css } from '@emotion/react';
import useWhatsApp from '../Hooks/useWhatsApp';

  
const ctaStyles = css`
    .cta-container {
        position: relative;
        background-color: #ffffff;
        padding: 60px 20px;
        text-align: center;
        border-radius: 12px;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
        margin: 40px auto;
        max-width: 800px;
    }

    .cta-highlight {
        color: #ff5722;
        font-weight: bold;
    }

    .cta-container > * {
        position: relative;
        z-index: 1;
    }

    .cta-button {
        background-color: #004d40;
        color: white;
        font-weight: bold;
        text-transform: none;
        font-size: 1.2rem;
        padding: 12px 30px;
        border-radius: 8px;
        margin-top: 30px;
        &:hover {
        background-color: #003731;
        }
    }
    .cta-bg {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: url('https://images.unsplash.com/photo-1636971181319-719decb479f2?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') center/cover no-repeat;
        opacity: 0.5; /* Increased for better visibility */
        z-index: 0; /* Adjust layering */
        border-radius: 12px;
    }
`;

const CallToAction = () => {
  const handleWhatsAppClick = useWhatsApp(); 
  return (
    <div css={ctaStyles}>
        <Box className="cta-container">
        <div className="cta-bg"></div>

        <Typography
            variant="h4"
            sx={{
            fontWeight: 700,
            marginBottom: '20px',
            color: '#004d40',
            }}
        >
            Your Time is <span className="cta-highlight">Now</span>!
        </Typography>

        <Typography
            variant="h6"
            sx={{
            marginBottom: '30px',
            color: '#5f6368',
            lineHeight: 1.8,
            }}
        >
            Imagine walking into a room and speaking fluent, confident English—
            <span className="cta-highlight">the respect, the opportunities, the transformation</span> it brings to your life.
            <br />
            Don’t let another day go by without taking the step to become the woman you’ve always dreamed of.
        </Typography>

        <Typography
            variant="h5"
            sx={{
            fontWeight: 600,
            color: '#d32f2f',
            marginBottom: '20px',
            }}
        >
            Enrollment ends soon. Seats are limited. Don’t miss this once-in-a-lifetime opportunity!
        </Typography>

        <Button onClick={handleWhatsAppClick} className="cta-button">
            Enroll Now and Transform Your Life
        </Button>

        <Typography
            sx={{
            fontSize: '0.9rem',
            marginTop: '15px',
            fontStyle: 'italic',
            color: '#757575',
            }}
        >
            Already 100+ women have joined this month—be the next success story.
        </Typography>
        </Box>
    </div>
  );
};

export default CallToAction;
